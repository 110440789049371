.wrapper {
  &.top {
    // @include mq(lg) {
    //   background: url(../img/top/bg_top_01_sp.jpg) no-repeat top center;
    //   background-size: 100%;
    // }
  }
}

.top {
  &-sec {
    padding: 80px 0 120px;
    @include mq(lg) {
      padding: 10.6vw 0;
    }
    &-lg {
      padding: 100px 0;
      @include mq(lg) {
        padding: 16vw 0;
      }
    }
  }
  &-hero {
    position: relative;
    height: 797px;
    background: url(../img/top/hero_pc.jpg) no-repeat top center;
    @include mq(lg) {
      background: url(../img/top/hero_sp.jpg) no-repeat top center;
      height: 125vw;
      background-size: cover;
    }
    &__catch {
      @include mq(xl) {
        position: relative;
        margin: 0 auto;
        width: 1020px;
      }

      @include mq(lg) {
        position: absolute;
        top: 18.9vw;
        left: 50%;
        width: 88.26vw;
        transform: translateX(-50%);
//        width: 55.26vw;
      }
      img {
        display: block;
        @include mq(xl) {
          position: absolute;
          top: 7.7vw;
          left: 0;
        }
        @media screen and (min-width:1020px) and (max-width:1280px) {
          left: 9%;
        }
      }
    }

  }
  &-sec-01 {
    position: relative;
    text-align: center;
    padding: 118px 0 100px;
    @include mq(lg) {
      overflow: hidden;
      padding: 11vw 0 15vw;
    }
    &__ttl {
      position: relative;
    }
    &__lead {
      margin-top: 58px;
      line-height: 2;
      @include mq(lg) {
        margin-top: 10vw;
        font-size: 2.4rem;
      }
    }
    .inner {
      position: relative;
        // position: absolute;
        // background: url(../img/top/bg_sec01_01_pc.png) no-repeat 0 0;
        // right: 0;
        // bottom: -40px;
        // width: 398px;
        // height: 326px;
        @include bonbonPC(-40px);
        @include mq(lg) {
          @include bonbonSP(-7.7vw);
        }
    }
  }
  &-sec-02 {
    background: #fff;
    &-wrap {
      position: relative;
      justify-content: space-between;
      @include mq(lg) {
        display: block;
      }
    }
    &__ttl {
      font-family: $mincho;
      font-size: 3rem;
      @include mq(lg) {
        text-align: center;
        font-size: 3.8rem;
      }
    }
    &__lead {
      margin-top: 38px;
      font-size: 1.6rem;
      @include mq(lg) {
        margin-top: 7.46vw;
        font-size: 2.8rem;
      }
    }
    &-txt {
      @include mq(xl) {
        flex-basis: 406px;
        max-width: 406px;
      }
    }
    &-photo {
      margin-top: 2.6vw;
    }
    &-btn {
      padding: 5px 0;
      @include mq(xl) {
        position: absolute;
        bottom: 0;
        left: 20px;
        bottom: 27px;
      }
      @include mq(lg) {
        margin: 8vw auto 0;
      }
    }
  }
  &-sec-03 {
    background: #ecf1fb;
    &-list {
      justify-content: space-between;
      @include mq(lg) {
        flex-direction: column;
        align-items: center;
      }
      &__items {
        text-align: center;
        @include mq(xl) {
          flex-basis: 304px;
          max-width: 304px;
        }
        &:nth-child(n+2) {
          @include mq(lg) {
            margin-top: 10.5vw;
          }
        }
        &-catch {
          margin-top: 35px;
          font-size: 2.6rem;
          font-family: $mincho;
          font-weight: 500;
          @include mq(lg) {
            margin-top: 4.3vw;
            font-size: 4.2rem;
          }
        }
        figure {
          // width: 100%;
          // height: 304px;
          @include mq(lg) {
            width: 58.6vw;
            height: auto;
          }

        }
        .hover-img {
          &-big {
            &:hover {
              .hover-img-big__photo {
                &:before {
                  opacity: .3;
                }
              }
            }
            &__photo {
              overflow: hidden;
              position: relative;
              @media all and (-ms-high-contrast: none) {
                &:before {
                  content:"";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 304px;
                  height: 304px;
                  background-size: contain;
                  z-index: 50;
                }
              }
              @supports (-ms-ime-align:auto) {
                &:before {
                  content:"";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 304px;
                  height: 304px;
                  background-size: contain;
                  z-index: 50;
                }               
              }
   
            }
            &:hover {
              img {
                @media all and (-ms-high-contrast: none){
                  transform: scale(1);
                  transition-duration: 0.3s;
                }
                @supports (-ms-ime-align:auto) {
                  transform: scale(1);
                }
    
              }
            }
          }
        }
       }
    }
    &-item {
      -webkit-mask-size: 304px;
      mask-size: 304px;
      @include mq(lg) {
        mask-size: 58.6vw;
        -webkit-mask-size: 58.6vw;
      }
      &--01 { 
        mask-image: url('../img/top/bg_mask_01.png');
        -webkit-mask-image: url('../img/top/bg_mask_01.png');

        @media all and (-ms-high-contrast: none){ 
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_01.png) no-repeat 0 0;
            opacity: 0;
          }
        }
        @supports (-ms-ime-align:auto) {
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_01.png) no-repeat 0 0;
            opacity: 0;
          }         
        }
      }
      &--02 {
        mask-image: url('../img/top/bg_mask_02.png');
        -webkit-mask-image: url('../img/top/bg_mask_02.png');
        @media all and (-ms-high-contrast: none){ 
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_02.png) no-repeat 0 0;
            opacity: 0;
          }
        }
        @supports (-ms-ime-align:auto) {
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_02.png) no-repeat 0 0;
            opacity: 0;
          }
        }
     }
      &--03 {
        mask-image: url('../img/top/bg_mask_03.png');
        -webkit-mask-image: url('../img/top/bg_mask_03.png');
        @media all and (-ms-high-contrast: none) {
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_03.png) no-repeat 0 0;
            opacity: 0;
          }
        }
        @supports (-ms-ime-align:auto) {
          &:before {
            transition: .3s;
            background: url(../img/top/bg_mask_ie_03.png) no-repeat 0 0;
            opacity: 0;
          }          
        }
      }

    }
    &-wrap {
      &--01 {
        background: url(../img/top/bg_sec03_01.png) no-repeat top center / cover;
      }
      &--02 {
        background: url(../img/top/bg_sec03_02.png) no-repeat top center / cover;
      }
      &--03 {
        background: url(../img/top/bg_sec03_03.png) no-repeat top center / cover;
      }

      // .hover-img-big__photo {
      //   @media all and (-ms-high-contrast: none){
      //     overflow: auto;
      //     img {
      //       position: relative;
      //     }
      //     &:hover {
      //       img {
      //         transform: scale(1); 
      //         /*画像の拡大率*/
      //         &:before {
      //           position: absolute;
      //           left: 0;
      //           top: 0;
      //           content:"";
      //           z-index: 3;
      //           background: rgba(#000,.3);
      //           width: 100%;
      //           height: 100%;
      //         }
              
      //       }
 
      //     }
      //   }
      //   @supports (-ms-ime-align:auto) {
      //     overflow: auto;
      //     &:hover {
      //       img {
      //         transform: scale(1); 
      //         /*画像の拡大率*/
  
      //       }
      //     }
      //   }
      // }
    }
  }
  &-sec-04 {
    &-list {
      @include mq(xl) {
        justify-content: center;
        
      }
      @include mq(lg) {
        flex-direction: column;
        align-items: center;
      }
      &__items {
        @include mq(xl) {
          flex-basis: 460px;
          max-width: 460px;
        }
        @include mq(lg) {
          width: 100%;
        }
        &:nth-child(2) {
          margin-left: 40px;
          @include mq(lg) {
            margin-left: 0;
            margin-top: 8vw;
          }
        }
      }
    }
  }
}

.fb-page {
  display:block !important;
  width:100%;
  max-width:500px;
  margin-left:auto;
  margin-right:auto;
  height:405px;
}
