$breakpoints: (
  'xl': 'screen and (min-width: 1021px)',//pc
  'lg': 'screen and (max-width: 1020px)',//sp
  'sm': 'screen and (max-width: 400px)', //iphone5
  'md': 'screen and (min-width: 768px) and (max-width: 1019px)',//other
  
  
) !default;

$gothic: -apple-system, BlinkMacSystemFont, "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", 'Noto Sans JP',sans-serif;
//$gothic: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ", "メイリオ", sans-serif;
$mincho: Georgia, "游明朝体", "YuMincho", "游明朝", "Yu Mincho", 'Noto Serif JP',serif;
$transition: .3s;

//color
$c-default: #333333;
$red-d: #96292b;
$blue: #141b61;
$blue-fade: #f5ffff;

