.hover {
  &-after {
    &-border {
      &--pc {
        @include mq(xl) {
          position: relative;
          &:hover {
            &:after {
              position: absolute;
              content: "";
              background: $blue;
              width: 8px;
              height: 1px;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }

          }

        }
      }


    }
  }
  &-border {
    &-txt {
      position: relative;
      display: inline-block;
      &:before {
        position: absolute;
        top: 2.3em;
        left: 0;
        content: "";
        display: inline-block;
        width: 0;
        height: 1px;
        background: #000;
        transition: .3s;
      }
    }
    &:hover {
      .hover-border-txt {
        &:before {
          width: 100%;
        }

      }
    }
  }
  &-txt {
    transition: $transition;
    &--blue {
      &:hover {
        color: $blue;
      }
    }
  }
  @include mq(xl) {
    &-img {
      &-big {
        &__photo {
          overflow: hidden;
          img {
            display: block;
            transition-duration: 0.3s;

          }
        }
        &:hover {
          img {
            transform: scale(1.1);
            /*画像の拡大率*/
            transition-duration: 0.3s;

          }
        }
      }
    }

  }
  &-color {
    transition: $transition;
    &--fade {
      @extend .hover-color;
      &:hover {
        opacity: .75;
      }
    }
    &--fb {
      @extend .hover-color;
      &:hover {
        svg {
          fill: #00276c;
        }
      }
    }
    &--insta {
      @extend .hover-color;
      &:hover {
        svg {
          fill: #D93177;
        }
      }
    }
  }
}

.accordion-toggle {
  &--sp {
    @include mq(lg) {
      position: relative;
      &:after {
        content: "";
        background: url(../img/common/ico_plus.png) no-repeat 0 0;
        position: absolute;
        top: 50%;
        right: 2.6vw;
        transform: translateY(-50%);
        width: 2.6vw;
        height: 2.6vw;
        background-size: 100%;
      }
    }
    &.megaMenuOpen {
      &:after {
        background: url(../img/common/ico_minus.png) no-repeat 0 0;
      }

    }
  }
}

.fade-off {
  opacity: 0;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  transform: translateY(15px);
}

.fade-on {
  opacity: 1;
  transform: translateY(0);
}

@include mq(xl) {
.megaMenuOpen-overlay {
  &:after {
  position: absolute;
  content: "";
  background: $blue;
  width: 8px;
  height: 1px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
}
  
}


