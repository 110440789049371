.fill {
  &-white {
    fill: #fff;
  }
  &-brown {
    fill: #422b2b;
  }
  &-black {
    fill: #000;
  }
  &-nara {
    fill: #00276c;
  }
}

.ico-window {
  
}

