.csr {
  &-intro {
    @include mq(lg) {
      overflow: hidden;
    }
    .inner {
      position: relative;
      &:before {
        content:"";
        position: absolute;
        background: url(../img/top/bg_sec01_01_pc.png) no-repeat right top;
        right: 5.9vw;
        top: -74px;
        width: 398px;
        height: 326px;
        @include mq(lg) {
          right: -6vw;
          top: 0;
          width: 62vw;
          height: 100%;
          background-size: 100%;
        }
      }
    }
    &__photo {
      margin-top: 10px;
      text-align: center;
    }
  }
  &-activity {
    &-ttl {
      &--secondary {
        @include mq(xl) {
          margin-bottom: 40px;
          
        }
        @include mq(lg) {
//          font-size: 3.8rem;
//          margin-bottom: 7.46vw;
        }
      }
      &-module {
        &--primary {
          padding-bottom: 9px;
          font-size: 2rem;
          @include mq(lg) {
            font-size: 3rem;
          }
        }
      }
    }
    &-box {
      &:nth-of-type(n+2) {
        margin-top: 60px;
        @include mq(lg) {
          margin-top: 10vw;
        }
      }
    }
  }
  &-talk {
    padding: 60px 0;
    background: #f5f7fc;
    @include mq(lg) {
      padding: 9.3vw 4%;
    }
    &-box {
      position: relative;
      max-width: 960px;
      margin: 0 auto;
      padding: 10px;
      background: #ffffff;
      @include mq(lg) {
        padding: 3vw;
      }
      &:before {
        content:"";
        position: absolute;
        background: url(../img/csr/bg_talk_01.png) no-repeat top right,url(../img/csr/bg_talk_02.png) no-repeat bottom right,url(../img/csr/bg_talk_03.png) no-repeat top left,url(../img/csr/bg_talk_04.png) no-repeat bottom left;
        background-size: 53px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; 
        @include mq(lg) {
          background-size: 30px;
        }
      }
      &__inner {
        padding: 35px 70px 52px;
        border: 1px solid #b29550;
        position: relative;
        @include mq(lg) {
          padding: 5vw;
          font-size: 2.4rem;
        }
      }
    }
    &__ttl {
      text-align: center;
      position: relative;
      margin-bottom: 24px;
      font-size: 2rem;
      font-family: $mincho;
      font-weight: 500;
      @include mq(lg) {
        font-size: 3rem;
      }
      span {
        font-size: 2.8rem;
        @include mq(lg) {
          font-size: 3.8rem;
        }
      }
      &:after {
        content:"";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-image: linear-gradient(to right, #a78b4d, #b7934e 25%, #e4e1b6 43%, #d6c286 69%, #c1984d);
        width: 520px;
        height: 2px;
        @include mq(lg) {
          width: 100%;
        }
      }
    }
  }
}
