.business {
  &-performance {
    &-ttl {
      margin-top: 50px;
    }
  }
  &-award-list {
      th {
        @include mq(xl) {
          text-align: right;
        }
      }
    td {
      @include mq(xl) {
        padding-left: 60px;
        
      }
    }
    @include mq(lg) {
      th,td {
        display: block;
      }
      th {
        width: 20%;
        display: inline-block;
      }
      tr {
        &:not(:first-child) {
          th {
            padding-top: 10px;
          }
        }
    
      }
    }
  }
  &-sec02 {
    background: url(../img/business/bg_business_01.jpg) no-repeat 0 0;
    background-size: cover;
    &-list {
      @include mq(xl) {
        display: flex;
        justify-content: space-between;
      }
    }
    &__items {
      padding: 37px 20px 80px;
      background: #ffffff;
      border-top: 2px solid #343d96;
      @include mq(lg) {
        padding: 5vw;
      }
      &--2col {
        flex-basis: 48.9%;
        max-width: 48.9%;
        @include mq(lg) {
          max-width: 100%;
        }
        &:nth-child(n+2) {
          @include mq(lg) {
            margin-top: 10vw;
          }
        }
      }
    }
    &__ttl {
      margin-bottom: 50px;
      font-weight: bold;
      font-size: 2.2rem;
      text-align: center;
      @include mq(lg) {
        margin-bottom: 5vw;
        font-size: 3.5rem;
      }
    }
    &-summary {
      margin-top: 40px;
      padding: 60px 40px 40px;
      background: rgba(#ffffff,.8);
      @include mq(lg) {
        margin-top: 10vw;
        padding: 5vw;
      }
      &__ttl {
        text-align: center;
        font-weight: bold;
        font-size: 2.2rem;
        @include mq(lg) {
          font-size: 3.5rem;
        }
      }
      &__txt {
        margin-top: 35px;
        @include mq(lg) {
          margin-top: 5vw;
        }
        &-box {
          margin-top: 30px;
          text-align: center;
          @include mq(lg) {
            margin-top: 5vw;
            text-align: left;
          }
          p {
            &:nth-child(n+2) {
              margin-top: 30px;
              @include mq(lg) {
                margin-top: 5vw;
              }
            }
          }
          &+p {
            margin-top: 35px;
            font-size: 2rem;
            @include mq(lg) {
              margin-top: 5vw;
              font-size: 4rem;
            }
          }
        }
      }
      &__catch {
        font-size: 3.2rem;
        text-align: center;
        @include mq(lg) {
          font-size: 3.5rem;
        }
      }
      .small {
        margin-top: 50px;
        font-size: 1.4rem;
        @include mq(lg) {
          margin-top: 5vw;
        }
      }
    }
  }
  &-sec03 {
    background: #edf2fc;
    &__ttl {
      margin-bottom: 40px;
      padding: 12px 0 12px 20px;
      background: #343d96;
      color: #ffffff;
      font-size: 2.8rem;
      line-height: 1.2;
      font-weight: bold;
      @include mq(lg) {
        margin-bottom: 5vw;
        font-size: 3.5rem;
      }
      &:nth-of-type(n+2) {
        margin-top: 80px;
        @include mq(lg) {
          margin-top: 15vw;
        }
      }
    }
    &-txt {
      max-width: 460px;
      &--right {
        margin-left: auto;
      }
    }    
    &-box {
      position: relative;
      padding: 24px 0;
      background: #ffffff;
      max-width: 940px;
      @include mq(xl) {
        min-height: 320px;
      }
      @include mq(lg) {
        padding: 5vw;
      }    
      &--left {
        margin-top: 80px;
        @include mq(xl) {
          padding-left: 20px;
        }
        @include mq(lg)  {
          margin-top: 10vw;
        }
      }
      &--right {
        margin-top: 60px;
        @include mq(xl) {
          padding-right: 20px;
          margin-left: auto;
        }
        @include mq(lg) {
          margin-top: 10vw;
          display: flex;
          flex-direction: column;
        }
      }

    }
    &__heading {
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #bfbfbf;
      font-size: 2.2rem;
      @include mq(lg) {
        font-size: 3.5rem;
        margin-bottom: 5vw;
        padding-bottom: 3vw;
      }
      &-ico {
        text-align: center;
        display: inline-block;
        width: 64px;
        padding: 10px;
        color: #ffffff;
        margin-right: 20px;
        border-radius: 6px;
        line-height: 1;
        &.__blue {
          background: #4c62cc;
        }
        &.__green {
          background: #a5e856;
        }
      }
    }
    &-photo {
      @include mq(xl) {
        position: absolute;

      }
      @include mq(lg) {
        margin-top: 5vw;
      }
        &--right {
          right: -40px;
          top: -20px;
        }
        &--left {
          left: -40px;
          top: -20px;
          @include mq(lg) {
            order: 1;
          }
        }
    }
  }
  &-place {
    &__ttl {
      margin-bottom: 24px;
      font-size: 1.8rem;
      font-weight: bold;
      border-bottom: 1px solid #2231c1;
      @include mq(lg) {
        margin-bottom: 5vw;
        font-size: 3.5rem;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__items {
      flex-basis: 100%;
      &:nth-child(n+2) {
        margin-top: 60px;
        @include mq(lg) {
          margin-top: 10vw;
        }
      }
      &--2col {
        @include mq(xl) {
          flex-basis: 48.16%;
        }
      }
    }
    &__map {
      dt {
        margin-bottom: 15px;
      }
      @include mq(xl) {
        dd {
          width: 100%;
          height: 320px;
          overflow: hidden;
        }
        iframe {
          width: 100%;
          height: 650px;
          margin-top: -136px;
        }
      }
      iframe {
        @include mq(lg) {
          width: 100%;
          height: 320px;
        }

      }
    }
    &__inner {
      &--2col {
        @include mq(xl) {
          display: flex;
          justify-content: space-between;
        }
        .business-place__map {
          flex-basis: 48.16%;
          &:nth-child(n+2) {
            @include mq(lg) {
              margin-top: 10vw;
            }
          }


        }
      }
    }
  }
}

.room {
  &-management {
    border: 1px #ddd solid;
    background: #fff;
    @include mq(lg) {
      font-size: 2.6rem;
    }
    th,
    td {
      border: 1px #ddd solid;
      vertical-align: middle;
    }
    thead {
      th,td {
        font-weight: bold;
      }
    }
    tbody {
      .room-management-comment {
        font-size: 1.8rem;
        color: #ff0101;
        font-weight: bold;
        @include mq(lg) {
          font-size: 2.8rem;
        }
      }
    .room-management-roomNum {
      text-align: left;
      @include mq(xl) {
        padding-left: 40px;
      }
    }
    }
    &-prefecture,
    &-roomNum,
    &-categoryRoom,
    &-comment
    {
      padding: 16px 0;
      @include mq(lg) {
        padding: 10px 0;
      }
    }
    &-prefecture,
    &-roomNum {
      width: 120px;
      @include mq(lg) {
        min-width:70px;
      }
    }
    &-categoryRoom,
    &-comment {
      padding-left: 40px;
      width: 370px;
      @include mq(lg) {
        padding-left: 10px;
        padding-right: 10px;
        min-width:200px;
      }
    }
    &-prefecture {
      text-align: center;
    }
    &-comment {
    }
    &-roomNum {
      @include mq(xl) {
      text-align: center;
      }
       @include mq(lg) {
        padding-left: 10px;
      }
   }

  }
}
