.companies {
  &-ttl {
    &--secondary {
      @include mq(xl) {
        
        
      }
    }
  }
  &-active {
    &-summary {
      margin-top: 120px;
      text-align: center;
      font-family: $mincho;
      background: url(../img/for-companies/bg_mail.png) no-repeat center center;
      background-size: contain;
      padding: 138px 0 98px;
      @include mq(xl) {
        height: 447px;
        
      }
      @include mq(lg) {
        margin-top: 10vw;
        background-size: 100% 100%;
        padding: 20vw 0 15vw 0;
      }
      &-box {
        margin-left: -40px;
        @include mq(lg) {
          margin-left: -8vw;
        }
      }
      &__ttl {
        font-size: 3rem;
        @include mq(lg) {
          font-size: 2.8rem;
        }
      }
      &__txt {
        margin-top: 20px;
        line-height: 3;
        font-size: 1.8rem;
        font-family: $gothic;
        @include mq(lg) {
          margin-top: 5vw;
          line-height: 2;
          font-size: 2.2rem;
        }
      }
    }
  }
}