@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin btn-red($radius) {
  border-radius: $radius;
  background: $red-d;
  color: #fff;
  transition: $transition;
  border: 1px $red-d solid;
  &:hover {
    border: 1px $red-d solid;
    color: $red-d;
    background: #fff;
    .fill-white {
      fill:$red-d;
    }
  }
} 
@mixin btn-blue($radius) {
  border-radius: $radius;
  background: $blue;
  color: #fff;
  transition: $transition;
  border: 1px $blue solid;
  &:hover {
    border: 1px $blue solid;
    color: $blue;
    background: #fff;
    .fill-white {
      fill:$blue;
    }
  }
} 
@mixin btn-brown($radius) {
  border-radius: $radius;
  border: 1px #502f1b solid;
  color: #502f1b;
} 
@mixin btn-border-blue($radius) {
  border-radius: $radius;
  border: 1px $blue solid;
  color: $blue;
} 

@mixin btn-white($radius) {
  border-radius: $radius;
  border: 1px #fff solid;
  color: #fff;
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}

@mixin bonbonPC($bottom) {
    &:before {
      content:"";
      position: absolute;
      background: url(../img/top/bg_sec01_01_pc.png) no-repeat 0 0;
      right: 0;
      bottom: $bottom;
      width: 398px;
      height: 326px;
    }
}

@mixin bonbonSP($bottom) {
  &:before {
    right: -6vw;
    bottom: $bottom;
    width: 62vw;
    height: 54vw;
    background-size: 100%;
  }

}

@mixin marker($color) {
  background: #ffffff;
  background: -o-linear-gradient(top, transparent 0%, transparent 70%, $color 50%, $color 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), color-stop(50%, $color), to($color));
  background: linear-gradient(to bottom, transparent 0%, transparent 70%, $color 50%, $color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='$color',GradientType=0 );
  display: inline-block;
  line-height: 1;
}

.marker {
  &-yellow {
    @include marker(#ffff00);
  }
}