input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"]
  {
  padding: 10px;
  border-radius: 0;
  outline: none;
  background: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 10px;
  border: 0;
  outline: none;
  background: transparent;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

button {
  &:hover {
    cursor: pointer;
  }
  
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:#ddd;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:#ddd;
}
::placeholder{ /* Others */
 color:#ddd
}




.table {
  &-input {
    &-c {
      &--default {
        border: solid 1px #dddddd;
      }
    }
    &-size {
      &--normal {
        width: 100%;
        font-size: 1.6rem;
        line-height: 1.3;
        @include mq(xl) {
          height: 42px;
        }
        @include mq(lg) {
          font-size: 2.6rem;
        }
      }
    }

  }
}
.txtarea {
  &-size {
    &--normal {
      width: 100%;
      font-size: 1.6rem;
      line-height: 1.3;
      height: 252px;
      @include mq(lg) {
        font-size: 2.6rem;
      }
    }
    
  }
}

.custom-selectbox {
  &__items {
    width: 100%;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px 38px 10px 10px;
    height: 42px;
    background: url(../img/contact/ico_select_btn.png) right 5px center / auto 70% no-repeat;
    font-size: 1.6rem;
    @include mq(lg) {
      font-size: 2.8rem;
    }
    &::-ms-expand {
      display: none;
    }
  }
}

//.custom-selectbox {
//  overflow: hidden;
//  width: 100%;
//  position: relative;
//  &:before {
//    position: absolute;
//    top: 15px;
//    right: 15px;
//    padding: 0;
//    content: '';
//    pointer-events: none;
////left: 3px;
//    width: 10px;
//    height: 10px;
//    border-top: 1px solid #fff;
//    border-right: 1px solid #fff;
//    -webkit-transform: rotate(135deg);
//    transform: rotate(135deg);
//    z-index: 2;
//  }
//  &:after {
//    position: absolute;
//    top: 5px;
//    right: 5px;
//    bottom: 5px;
//    width: 32px;
//    content: '';
//    background: #e9adaf;
//  }
//  &__items {
//    width: 100%;
//    padding-right: 1em;
//    cursor: pointer;
//    text-indent: 0.01px;
//    text-overflow: ellipsis;
//    outline: none;
//    background: transparent;
//    background-image: none;
//    box-shadow: none;
//    -webkit-appearance: none;
//    appearance: none;
//    padding: 8px 38px 8px 8px;
//    color: #666666;
//    height: 42px;
//    &::-ms-expand {
//      display: none;
//    }
//  }
//
//}
