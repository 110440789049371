.company {
  &-ttl {
    &--secondary {
      margin-bottom: 60px;
      @include mq(lg) {
        margin-bottom: 7.46vw;
      }
    }
  }
  &-intro {
    padding: 112px 0;
    @include mq(lg) {
      padding: 9.3vw 0 16vw;
    }
    .inner {
      position: relative;
    }
    &__logo {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 21.8%;
      @include mq(lg) {
        right: 6%;
        width: 40vw;
      }
    }
    &__ttl {
      font-size: 2.8rem;
      font-weight: bold;
      margin-left: 11.8%;
      font-family: $mincho;
      @include mq(lg) {
        margin-left: 5vw;
      }
    }
    &-box {
      &__inner {
        height: 293px;
        @include mq(md) {
          height: 630px;
        }
      }
      p {
        line-height: 2.8;
        @include mq(lg) {
          font-size: 2.4rem;
        }
      }
    }
  }
  &-about {
    &-table {
      margin: 0 auto;
      font-size: 1.4rem;
      line-height: 1.5;
      @include mq(lg) {
        font-size: 2.4rem;
      }
      tr {

      }
      tbody {
        & > tr {
          th,
          td {
            padding: 20px 0;
          }
          th {
            width: 246px;
            padding-left: 80px;
            font-weight: bold;
            @include mq(lg) {
              width: 30vw;
              text-align: left;
              padding-left: 4vw;
            }
          }
          td {
            padding-left: 100px;
            @include mq(lg) {
              padding-left: 4vw;
            }
          }
        }

      }
      &-child {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        dt {
          width: 100px;
          @include mq(lg) {
            width: 20vw;
          }
        }
      }
    }
  }
  &-direction {
    &__inner {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      @include mq(xl) {
        justify-content: space-between;
      }
      @include mq(lg) {
        flex-direction: column;
      }
    }
    &__txt {
      font-size: 1.4rem;
      line-height: 2;
      @include mq(xl) {
        flex-basis: 63.6%;
        max-width: 63.6%;
      }
      @include mq(lg) {
        font-size: 2.8rem;
      }
      &__ttl {
        font-size: 2.8rem;
        @include mq(lg) {
          font-size: 3.8rem;
        }
      }
    }
    &__photo {
      @include mq(lg) {
        margin: 0 auto 5vw;
        width: 70%;
      }
    }
    &-sign {
      margin-top: 34px;
      text-align: right;
    }
  }
  &-rinen {
    padding-bottom: 370px;
    background: url(../img/company/bg_rinen_01.jpg) no-repeat center bottom;
    background-size: cover;
    @include mq(lg) {
      font-size: 2.8rem;
      padding-bottom: 50vw;
    }
    &__txt {
      text-align: center;
      line-height: 2;
      font-size: 1.8rem;
      @include mq(lg) {
        font-size: 2.8rem;
      }
    }
  }
  &-heart {
    &-box {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      &__img {
        &-01 {
          flex-basis: 47.4%;
        }
        &-02 {
          flex-basis: 47.4%;
          @include mq(xl) {
            margin-top: 50px;
          }
        }
      }
    }
    &-summary {
      margin-top: 10px;
      padding: 30px 0;
      background: url(../img/company/bg_heart_01.jpg) no-repeat 0 0;
      background-size: cover;
      color: #502f1b;
      text-align: center;
      @include mq(lg) {
        margin-top: 5vw;
        padding: 5vw 2vw;
        font-size: 2.6rem;
      }
      &-list {
        display: inline-block;
        text-align: left;
      }
    }
  }
  &-other-box {
    &:nth-of-type(n+2) {
      padding-top: 120px;
      @include mq(lg) {
        padding-top: 9.3vw;
      }
    }
  }
  &-rule {
    position: relative;
    padding-bottom: 252px;
    @include mq(lg) {
      padding-bottom: 15vw;
    }
    &-box {
      text-align: right;
      padding-right: 100px;
      @include mq(xl) {
        position: relative;
      }
      @include mq(lg) {
        padding-right: 20vw;
      }
      &:before {
        position: absolute;
        content: "";
        background: url(../img/company/img_rule_01.png) no-repeat 0 0;
        left: -40px;
        width: 623px;
        height: 660px;
        z-index: 10;
        @include mq(xl) {
          top: -17px;
        }
        @include mq(lg) {
          width: 50vw;
          height: 74vw;
          background-size: contain;
          bottom: 0;
          left: 2%;
        }

      }
      &__ttl {
        position: absolute;
        right: 0;
        @include mq(lg) {
          right: 4%;
          width: 15vw;
        }
      }
      p {
        line-height: 2.2;
        font-size: 1.8rem;
        @include mq(lg) {
          font-size: 2.6rem;
        }
      }
      &__inner {
        height: 396px;
        @include mq(md) {
          height: 810px;
        }
      }
    }
  }

}
