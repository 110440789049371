// .bg-bonbon {
//   background: url(../img/common/bg_entire_pc.png) no-repeat top center;
//   @include mq(lg) {
//     background: url(../img/common/bg_entire_sp.png) repeat-y top center;
//     background-size: cover;
//   }
// }

.border {
  &-before {
    padding-left: 10px;
    display: inline-block;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background: #fff;
      width: 4px;
      height: 1px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-under {
    &--black {
      border-bottom: 1px #333 solid;
    }
  }
}


.bread-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  font-size: 1.3rem;
  @include mq(xl) {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  @include mq(lg) {
    margin-top: 2vw;
    padding-left: 2.6vw;
    font-size: 2.6rem;
  }
  li {
    &:nth-child(n+2) {
      &:before {
        content: ">";
        margin: 0 10px;

      }

    }
    a {
      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
  }
}

.lower {
  &-container {
    position: relative;
    @include mq(xl) {
      padding-top: 40px;
    }
  }
  &-wrap {
    &--normal {
      padding: 110px 0;
      @include mq(lg) {
        padding: 9.3vw 0 16vw;
      }
    }
    &--sm {
      padding: 80px 0;
      @include mq(lg) {
        padding: 9.3vw 0 16vw;
      }
    }
    &--mix {
      padding: 90px 0 120px;
      @include mq(lg) {
        padding: 9.3vw 0 16vw;
      }
      &--02 {
        padding: 80px 0 120px;
        @include mq(lg) {
          padding: 9.3vw 0 16vw;
        }       
      }
    }
    &--mix-rv {
      padding: 120px 0 80px;
      @include mq(lg) {
        padding: 16vw 0vw;
      }
    }
    &--mix-sm {
      padding: 90px 0 80px;
      @include mq(lg) {
        padding: 9.3vw 0 16vw;
      }
    }

  }
  &-ttl {
    //大見出し
    &--primary {
      padding: 60px 0 45px;
      background: #ecf1fb;
      background-size: cover;
      text-align: center;
      font-size: 3.6rem;
      font-family: $mincho;
      color: $blue;
      line-height: 1;
      @include mq(lg) {
        padding: 7.7vw 0;
      }
      &.csr {
        letter-spacing: 9.2px;
        @include mq(xl) {
          font-size: 4.6rem;
        }

      }
    }
    &--secondary {
      font-family: $mincho;
      font-weight: bold;
      margin-bottom: 45px;
      ._small {
        font-size: 2rem;
        @include mq(lg) {
          font-size: 2.4rem;
        }
      }
      @include mq(xl) {
        font-size: 2.8rem;
        
      }
      @include mq(lg) {
        margin-bottom: 7.46vw;
        font-size: 3.8rem;
      }

    } //セクションボックスのモジュール内の見出し
    &-module {
      &--primary {
        position: relative;
        border-bottom: 1px #bfbfbf solid;
        margin-bottom: 25px;
        font-size: 2.2rem;
        @include mq(lg) {
          margin-bottom: 5vw;
          font-size: 3.2rem;
        }
        &:after {
          display: block;
          position: absolute;
          content: "";
          background: #2231c1;
          width: 150px;
          height: 1px;
          bottom: 0;
          left: 0;
          margin-bottom: -1px;
        }
      }
    }
  }
  &-intro {
    &__catch {
      font-size: 2rem;
      text-align: center;
      font-family: $mincho;
      @include mq(lg) {
        font-size: 3.3rem;
      }
      & + .lower-intro__lead {
        margin-top: 60px;
        @include mq(lg) {
          margin-top: 5vw;
          font-size: 2.8rem;
        }
      }
    }
    &__lead {
      line-height: 2;
    }
  }
}

.cmn-ttl {
  margin-bottom: 60px;
  font-size: 2.8rem;
  font-family: $mincho;
  text-align: center;
  font-weight: 500;
  @include mq(lg) {
    margin-bottom: 7.4vw;
    font-size: 4.6rem;
    padding-bottom: 2vw;
  }
  &-border {
    position: relative;
    @extend .cmn-ttl;
    padding-bottom: 5px;
    @include mq(lg) {
      margin-bottom: 7.4vw;
      font-size: 4.6rem;
      padding-bottom: 2vw;
    }
    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $blue;
      height: 2px;
      width: 40px;
    }
  }
}

.photobox-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  @include mq(lg) {
    justify-content: space-between;
  }
  &__items {
    flex-basis: 31%;
    @include mq(xl) {
      max-width: 31%;
    }
    @include mq(lg) {
      flex-basis: 49%;
      &:nth-child(n+3) {
        margin-top: 3vw;
      }
    }
    @include mq(xl) {
      &:not(:nth-child(3n)) {
        margin-right: 3.46%;
      }
    }
    @include mq(xl) {
      &:nth-child(n+4) {
        margin-top: 40px;
      }
    }
  }
  &__caption {
    margin-top: 10px;
    @include mq(lg) {
      margin-top: 1vw;
      font-size: 2.4rem;
    }
  }
}

.tate-box {
  text-align: center;
  &__inner {
    writing-mode: vertical-rl;
    column-count: 1;
    display: inline-block;
    text-align: left;
  }
}

.modlue {
  &-txt-note {
    margin-top: 15px;
    font-size: 1.4rem;
    @include mq(lg) {
      font-size: 2.4rem;
    }
  }
  &-photo {
    margin-top: 60px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    @include mq(lg) {
      margin-top: 5vw;
    }
    &--2col {
      @extend .modlue-photo;
      justify-content: space-between;
      @include mq(lg) {
        flex-direction: column;
      }
    }
    &__items {
      &--2col {
        //        flex-basis: 47.5%;
        &:nth-child(n+2) {
          @include mq(lg) {
            margin-top: 3vw;
          }
        }
      }
      &--3col {
        //        flex-basis: calc(100 / 3);
        &:nth-child(n+2) {
          margin-left: 1px;
        }
      }
    }
  }
  &-txtphoto-box {
    & + & {
      margin-top: 80px;
      @include mq(lg) {
        margin-top: 10vw;
      }
    }
  }
}

.sp-trimming {
  @include mq(lg) {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  &-companies {
    @extend .sp-trimming;
    object-position: 45% 50%;
  }
}

.ico-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 50px;
  align-items: center;
  @include mq(lg) {
    justify-content: center;
  }
  &__items {
    &:nth-child(n+2) {
      margin-left: 45px;
      @include mq(lg) {
        margin-left: 10vw;
      }
    }
  }
  &__link {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
  }
  &__ico {
    @include mq(xl) {
      margin-right: 15px;
    }
  }
  &__name {
    @include mq(lg) {
      display: none;
    }
  }
}

.ico {
  &-fb {
    width: 45px;
    height: 45px;
  }
  &-insta {
    width: 45px;
    height: 45px;
  }
}

.table-scroll {
  @include mq(lg) {
    min-width: 100%;
  }
}

.cmn-table {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  &-line {
    &:nth-child(n+2) {
      border-top: 1px solid #dddddd;
    }
  }
  &-th {
    background: #ecf1fb;
  }
}

.intro-bonbon {
  position: relative;
  @include bonbonPC(0px);
  &:before {
    top: -65px;
    width: 310px;
    height: 268px;
    background-size: 100%;
  }
}