.btn {
  &-border {
    text-align: center;
    display: block;
    border-radius: 5px;
    border: solid 1px;
    transition: $transition;
    &--red {
      @extend .btn-border;
      color: $red-d;
      border-color: $red-d;
      &:hover {
        background: $red-d;
        color: #fff;
      }
      &-sm {
        @extend .btn-border--red;
        width: 200px;
        @include mq(lg) {
          width: 80vw;
        }
      }
    }
    &--blue {
      @extend .btn-border;
      color: $blue;
      border-color: $blue;
      &:hover {
        background: $blue;
        color: #fff;
      }
      &-sm {
        @extend .btn-border--blue;
        width: 200px;
        @include mq(lg) {
          width: 80vw;
        }
      }
    }
  }
  &-sm {
    text-align: center;
    display: block;
    width: 280px;
    padding: 20px 0;
    font-size: 2rem;
    @include mq(lg) {
      padding: 3.3vw 0;
      font-size: 3rem;
      width: 50.6vw;
    }
    &--2col {
      @include mq(lg) {
        width: 40vw;
      }
    }
  }
  &-radius {
    border-radius: 5px;
    transition: $transition;
    font-weight: bold;
    &--pink {
      @extend .btn-radius;
      background: #e9adaf;
      color: #fff;
      border: 1px #e9adaf solid;
      &:hover {
        background: #fff;
        border: 1px #e9adaf solid;
        color: #e9adaf;
      }
    }
    &--white {
      @extend .btn-radius;
      background: #fff;
      color: #e9adaf;
      border: 1px #e9adaf solid;
      &:hover {
        background: #e9adaf;
        color: #fff;
        border: 1px #e9adaf solid;
      }
    }
    &--blue {
      @extend .btn-radius;
      background: #8fafe9;
      color: #fff;
      border: 1px #8fafe9 solid;
      &:hover {
        background:#fff;
        color: #8fafe9;
        border: 1px solid #8fafe9;
      }
    }
    
  }
}

.header-contact-list {
  &__items {
    font-size: 1.4rem;
    @include mq(lg) {
      font-size: 3rem;
    }
    a {
      display: block;
    }
    &._mail {
      a {
        @include btn-blue(5px);
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0;
        line-height: 1.2;
        @include mq(lg) {
          padding: 4vw 0;
          font-size: 3.2rem;
        }
      }
      .ico-mail {
        margin-right: 20px;
        flex-basis: 38px;
        height: 30px;
        @include mq(xl) {
          max-width: 38px;
          width: 38px;
          height: 30px;
        }
        @include mq(lg) {
          flex-basis: 13.3vw;
          height: 10.4vw;
          margin-right: 7.4vw;
        }
      }
    }
    &._tel {
      text-align: center;
      a {
        @include btn-border-blue(5px);
        padding: 15px 0;
        line-height: 1.4;
        @include mq(lg) {
          @include btn-white(5px);
          padding: 3.7vw 0 2vw;
        }
      &:hover {
        cursor:auto;
      }
      }
      .font-bold {
        display: block;
        font-size: 2.4rem;
        @include mq(lg) {
          font-size: 6rem;
        }
        & + span {
          @include mq(lg) {
            font-size: 2.6rem;
          }
        }
      }
    }
 }
}