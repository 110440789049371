html {
  font-size: 62.5%;
  background: #FFF;
  @include mq(lg){
    font-size: calc(100vw / 75);
  }
}
body {
  font-size: 1.6rem;
  font-family: $gothic;
  color: $c-default;
  line-height: 1.8;
  @include mq(lg){
    color: #000;
    font-size: 3.0rem;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
  color: $c-default;
}

input:not([type="radio"]):not([type="checkbox"]),
button,
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

//pc tablet sp
//@media #{$bp-pc} {
//  .sp {
//    display: none !important;
//  }
//  .pc {
//    display: block !important;
//  }
//}
//@include mq(lg){
//  .pc {
//    display: none !important;
//  }
//}

.pc {
  @include mq(lg) {
    display: none!important;
  }
}

.sp {
  display: none;
  @include mq(lg) {
    display: block;
  }
}

.font {
  &-bold {
  font-weight: bold;
}
  &-note {
   color: #cb0000; 
  }
  &-note-d {
    color: #be022e;
  }
}

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.inner {
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 20px;
  @include mq(lg){
    padding: 0 4%;
  }
//  &--sm {
//    @extend .inner;
//    max-width: 1000px;
//  }
//  &--md {
//    @extend .inner;
//    max-width: 1060px;
//  }
//  &--lg {
//    @extend .inner;
//    max-width: 1120px;
//  }
}

.wrapper {
  @include mq(xl) {
    padding-left: 220px;
  }
  @include mq(lg) {
    margin-top: 16vw;
  }
}

.bg {
  &-orange {
    background: #f6ded2;
  }
  &-pink {
    &-l {
      background: #faf6f5;
    }
  }
  &-blue {
      background: $blue-fade;
  }
}

.align {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
}

.pt00 { padding-top:  0px !important; }
.pb00 { padding-bottom:  0px !important; }
